<template>
  <div>
    <CModal
      :title="modalTitle"
      :color="modalColor"
      :show.sync="isShowPopup"
      :closeOnBackdrop="closeOnBackdrop"
      :size="size"
    >
      <template #header>
        <h5>{{ modalTitle }}</h5>
        <CButtonClose
          aria-label="Close"
          class="close"
          @click="modalCallBack(false)"
        />
      </template>
      <template #footer>
        <CButton class="ml-2" color="secondary" @click="modalCallBack(false)"
          >Cancel</CButton
        >
        <CButton class="ml-2" color="primary" @click="onSubmit()"
          >Submit</CButton
        >
      </template>
      <div>
        <ValidationObserver
          ref="nurseRegistrationType"
          v-slot="{ handleSubmit }"
        >
          <form
            @submit.prevent="handleSubmit(onSubmit)"
            name="nurseRegistrationType"
          >
            <CRow name="area_of_practice" class="row mb-3">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="d-flex justify-content-between">
                  <label class="required"
                    >Is your other PG Degree relevant to your area of
                    practice?</label
                  >
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <RadioButton
                      class="text-nowrap"
                      name="any_higherqualification_related_to_practice"
                      :value="profile.any_higherqualification_related_to_practice"
                      :options="
                        options && options['boolean'] ? options['boolean'] : []
                      "
                      @change="handleChangeRadio"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </CRow>
            <CRow
              name="registered_as"
              class="row mb-3"
              v-if="profile.any_higherqualification_related_to_practice === true"
            >
              <label class="col-lg-12 col-md-12">Are you registered as a</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="d-flex justify-content-between">
                  <label class="required"
                    >Speciality Nurse or a Nurse(Advanced Nurse)
                    Practitioner?</label
                  >
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <RadioButton
                      class="text-nowrap"
                      name="speciality_nurse_or_nurse_practitioner"
                      :value="profile.speciality_nurse_or_nurse_practitioner"
                      :options="
                        options && options['boolean'] ? options['boolean'] : []
                      "
                      @change="handleChangeRadio"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import RadioButton from "@/components/reusable/Fields/RadioButton";
import Vue from "vue";
import { mapActions } from "vuex";
export default {
  name: "Modal",
  props: ["modalTitle", "modalColor", "isShowPopup", "closeOnBackdrop", "size"],
  components: { RadioButton },
  data() {
    return {
      profile: {},
    };
  },
  computed: {
    options() {
      return {
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
      };
    },
  },
  methods: {
    ...mapActions(["showToast"]),
    modalCallBack(action, data) {
      this.$emit("modalCallBack", action, data);
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.profile, name, val);
      if (name === "any_higherqualification_related_to_practice")
        this.handleChangeRadio("speciality_nurse_or_nurse_practitioner", null);
    },
    async onSubmit() {
      const isValid = await this.$refs.nurseRegistrationType.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let nurse_registration_type = null;
      if (this.profile?.any_higherqualification_related_to_practice) {
        nurse_registration_type = [
          "any_higherqualification_related_to_practice",
        ];
        if (this.profile?.speciality_nurse_or_nurse_practitioner)
          nurse_registration_type = [
            ...nurse_registration_type,
            "speciality_nurse_or_nurse_practitioner",
          ];
      }
      const payload = {
        nurse_registration_type,
      };
      this.modalCallBack(true, payload);
    },
  },
};
</script>