<template>
  <div>
    <div v-if="isFetching">
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Qualification</CCol>
        <CCol lg="7" class="text-black">{{ qualificationName || "--" }}</CCol>
      </CRow>
      <!-- <CRow class="profileView">
        <CCol lg="5" class="text-gray">{{ isNurse ? 'Main Speciality' : 'Speciality' }}</CCol>
        <CCol lg="7" class="text-black">{{ speciality }}</CCol>
      </CRow>
      <CRow v-if="isNurse" class="profileView">
        <CCol lg="5" class="text-gray">Other Expertise</CCol>
        <CCol lg="7" class="text-black">{{ otherExpertise }}</CCol>
      </CRow>
      <CRow v-if="isAHP" class="profileView">
        <CCol lg="5" class="text-gray">Sub Type / Licensed As</CCol>
        <CCol lg="7" class="text-black">{{ subType }}</CCol>
      </CRow> -->
      <CRow class="profileView" v-if="isHavingSubject">
        <CCol lg="5" class="text-gray">Subject</CCol>
        <CCol lg="7" class="text-black">{{
          qualification.description || "--"
        }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Country</CCol>
        <CCol lg="7" class="text-black">{{
          qualification.country_name || "--"
        }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Month / Year Obtained</CCol>
        <CCol lg="7" class="text-black">{{ qualification | monthYear }}</CCol>
      </CRow>
      <!-- <CRow class="profileView">
        <CCol lg="5" class="text-gray">Issued By</CCol>
        <CCol lg="7" class="text-black">{{
          qualification.issue_authority_name ||
          (qualification.other_issue_authority
            ? `Other - ${qualification.other_issue_authority}`
            : "--")
        }}</CCol>
      </CRow> -->
      <CRow v-if="qualification.by_exam" class="profileView">
        <CCol lg="5" class="text-gray">Is this by exam</CCol>
        <CCol lg="7" class="text-black">{{
          qualification.by_exam ? "Yes" : "No"
        }}</CCol>
      </CRow>
      <CRow v-if="qualification.course_duration" class="profileView">
        <CCol lg="5" class="text-gray">Course Duration (yrs)</CCol>
        <CCol lg="7" class="text-black">{{
          qualification.course_duration || "N/A"
        }}</CCol>
      </CRow>
      <CRow v-if="qualification.distance_learning" class="profileView">
        <CCol lg="5" class="text-gray">Distance Learning</CCol>
        <CCol lg="7" class="text-black">{{
          qualification.distance_learning ? "Yes" : "No"
        }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Sponsored Course</CCol>
        <CCol lg="7" class="text-black">
          {{ isSponsored ? `Yes` : "--" }}
        </CCol>
      </CRow>
      <CRow v-if="isSponsored" class="profileView">
        <CCol lg="5" class="text-gray">Sponsorship Duration</CCol>
        <CCol lg="7" class="text-black">
          {{ formatDateMonthYear(getSponsoredCandidates[0]?.start_date) }} / {{ formatDateMonthYear(getSponsoredCandidates[0]?.end_date) }}
        </CCol>
      </CRow>
      <CRow v-if="showDocument" class="profileView">
        <CCol lg="5" class="text-gray">Related Documents</CCol>
        <CCol lg="7" class="text-black"
          ><span
            v-if="qualification.documents && qualification.documents.length"
            ><a
              class="text-primary"
              style="cursor: pointer"
              v-for="(i, index) in qualification.documents"
              :key="index"
              @click="preview(i)"
              >{{ index == 0 ? i.document_name : `, ${i.document_name}` }}</a
            ></span
          >{{
            qualification.documents && qualification.documents.length
              ? ""
              : "--"
          }}</CCol
        >
      </CRow>
    </div>
    <preview-modal
      modalTitle="Document Preview"
      modalColor="primary"
      :buttons="['Ok']"
      size="lg"
      :isShowPopup="previewModal.isShowPopup"
      :modalCallBack="previewModalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import pdf from "vue-pdf";
import moment from "moment";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import PreviewModal from "../../../../components/reusable/PreviewModal.vue";
import { mapActions, mapGetters } from "vuex";
import { getScope, Role, appendAccessToken } from "@/helpers/helper";
import { BASE_URL } from "@/service_urls";
import m from "moment";
export default {
  components: { PreviewModal },
  props: {
    qualification: { type: Object, default: () => {} },
    mainSpeciality: { type: Array, default: () => [] },
    otherSpeciality: { type: Array, default: () => [] },
    candidateType: { type: Number, default: () => null },
    profile: { type: Object, default: () => {} },
  },
  data: () => ({
    previewModal: {
      isShowPopup: false,
      isPreview: false,
    },
    iframe: {
      src: "",
      loaded: false,
      isImg: false,
      pdf: {
        ispdf: false,
        src: "",
        numPages: undefined,
        addContentClasses: "",
      },
    },
  }),
  computed: {
    ...mapGetters(["isJobSupplierFromAccessToken", "isUKMainAgency", "getSponsoredCandidates"]),
    isFetching() {
      return (
        isObject(this.qualification) && !isEmptyObjectCheck(this.qualification)
      );
    },
    qualificationName() {
      return this.qualification?.qualification_name?.includes(
        "Other - not listed"
      )
        ? `Other${
            this.qualification.other_qualification_name
              ? " - " + this.qualification.other_qualification_name
              : ""
          }`
        : this.qualification.qualification_with_acronym;
    },
    speciality() {
      return (
        (this.mainSpeciality.length &&
          this.mainSpeciality
            .map(({ speciality: { speciality } }) => speciality)
            .join()) ||
        "--"
      );
    },
    subType() {
      return (
        (this.mainSpeciality.length &&
          this.mainSpeciality
            .map(({ sub_speciality }) => sub_speciality?.sub_speciality)
            .join()) ||
        "--"
      );
    },
    otherExpertise() {
      return (
        _(this.otherSpeciality)
          .filter((speciality) => {
            return (
              speciality.main != true &&
              !speciality.other_special_interest &&
              !speciality.special_interest
            );
          })
          .map((speciality) => {
            return speciality.speciality
              ? speciality.speciality?.speciality
              : speciality?.other_speciality;
          })
          .value()
          .join(", ") || "--"
      );
    },
    isNurse() {
      return this.candidateType == 2 ? true : false;
    },
    isAHP() {
      return this.candidateType == 3 ? true : false;
    },
    isHavingSubject() {
      return (
        (this.qualification?.qualification?.allow_subject_input &&
          this.qualification?.description) ||
        false
      );
    },
    showDocument() {
      return !this.isJobSupplierFromAccessToken
        ? true
        : this.isJobSupplierFromAccessToken &&
          this.profile?.currently_in_recruitment_process
        ? true
        : false;
    },
    isSponsored() {
      return this.profile?.is_sponsored
    }
  },
  filters: {
    monthYear: function (data) {
      let month_year = "";
      if (data.month_awarded)
        month_year = moment.monthsShort()[data.month_awarded - 1];
      if (data.year_awarded) month_year = `${month_year} ${data.year_awarded}`;
      return month_year || "--";
    },
  },
  methods: {
    ...mapActions(["showToast", "fetchSponsoredCandidates"]),
    showPreview(data) {
      const { uuid_link_to_doc } = data;
      const _baseUrl = BASE_URL;
      const _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
      // const _base_domain = "https://" + window.location.host;
      return appendAccessToken(`${_base_domain}${uuid_link_to_doc}`);
    },
    preview(data) {
      data.link_to_document = this.showPreview(data);
      let document_ext = data.document_ext?.document_ext;
      if (
        document_ext === "PNG" ||
        document_ext === "JPG" ||
        document_ext === "JPEG" ||
        document_ext === "GIF"
      ) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = data.link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(
          data.link_to_document
        );
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        document_ext === "TXT" ||
        document_ext === "EPS" ||
        document_ext === "TIF" ||
        document_ext === "TIFF" ||
        document_ext === "RAW"
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          data.link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    previewModalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    formatDateMonthYear(date) {
      return date ? m(date).format("DD MMM YYYY") : "--";
    },
  },
  mounted() {
    this.fetchSponsoredCandidates(this.profile?.candidate_uid)
  }
};
</script>
