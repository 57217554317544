<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <a
        class="d-flex btn pl-0"
        @click="
          ($parent.isExpand.privacyAndTerms = !$parent.isExpand
            .privacyAndTerms),
            $parent.checkExpandAll()
        "
      >
        <span class="pr-1">
          <i
            class="text-primary fas"
            :class="
              $parent.isExpand.privacyAndTerms
                ? 'fa-angle-down'
                : 'fa-angle-right'
            "
            alt="angle-down"
          />
        </span>
        <h5 class="text-primary text-left">
          Privacy & Terms
        </h5>
      </a>
    </div>
    <div v-if="$parent.isExpand.privacyAndTerms" class="gray-box">
      <CRow class="profileView">
        <CCol col="5" class="text-gray">Subscription</CCol>
        <CCol col="7" class="text-black"
          >{{ profile.subscription_preferences | yesOrNo
          }}{{
            profile.subscription_preferences && subscribtionPackages
              ? ` (${subscribtionPackages}) `
              : ""
          }}</CCol
        >
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">How did you hear about us?</CCol>
        <CCol lg="7" class="text-black">{{ hearAboutUs }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">How did we do?</CCol>
        <CCol lg="7" class="text-black"
          >{{ profile.our_rating || "--" }}
        </CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">What could we do better?</CCol>
        <CCol lg="7" class="text-black">{{ profile.do_better || "--" }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Applied to other agencies</CCol>
        <CCol lg="7" class="text-black">{{
          profile.applied_to_other_agencies | yesOrNo
        }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Subscribed</CCol>
        <CCol lg="7" class="text-black">{{
          profile.subscribed | subscriptionStatus
        }}</CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    profile: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {};
  },
  computed: {
    hearAboutUs() {
      return this.profile?.display_data?.display_data || "--";
    },
    subscribtionPackages() {
      let data = "";
      this.profile.new_job_opportunities
        ? (data = "New Job Opportunities")
        : null;
      this.profile.urgent_messages_and_alerts
        ? data
          ? (data = data + ", Urgent Messages and Alerts")
          : "Urgent Messages and Alerts"
        : null;
      this.profile.invitations_relating_to_jobs
        ? data
          ? (data = data + ", Invitations relating to Jobs")
          : "Invitations relating to Jobs"
        : null;
      this.profile.promotions_and_press_releases
        ? data
          ? (data = data + ", Promotions & Press Releases")
          : "Promotions & Press Releases"
        : null;
      return data;
    },
  },
  filters: {
    yesOrNo: function(data) {
      if (data != null && typeof data === "boolean") return data ? "Yes" : "No";
      return "--";
    },
    subscriptionStatus: function(data) {
      if(typeof data === "boolean") return data? "No" : "Yes";
      return "Yes";
    }
  },
};
</script>
