<template>
  <div class="splInterest-info-section">
    <div class="d-flex align-items-center justify-content-between pb-2">
      <a
        class="d-flex btn pl-0"
        @click="
          ($parent.isExpand.specialization = !$parent.isExpand.specialization),
            $parent.checkExpandAll()
        "
      >
        <span class="pr-1">
          <i
            class="text-primary fas"
            :class="
              $parent.isExpand.specialization
                ? 'fa-angle-down'
                : 'fa-angle-right'
            "
            alt="angle-down"
          />
        </span>
        <h5 class="text-primary text-left">
          Specialization
          <span class="nowrap"
            >[{{ nurseSpeciality.length }} Record{{
              nurseSpeciality.length > 1 ? "s" : ""
            }}]</span
          >
        </h5>
      </a>
      <div
        class="d-flex justify-content-end sticky-header"
        v-if="$parent.isExpand.specialization"
      >
        <CButton
          v-if="isEditable && nurseSpeciality.length < 4"
          class="btn-primary small mr-1 mt-1"
          style="height: 28px"
          @click="addSpecialization()"
          v-c-tooltip="'Click to Add multiple records!'"
          >Add</CButton
        >
      </div>
    </div>
    <div v-if="$parent.isExpand.specialization">
      <div v-if="isMobile">
        <h6 v-if="nurseSpeciality.length" class="pb-1 fw-600 pt-2 pb-2">
        Specializations :
        </h6>
        <div v-for="(speciality, index) in nurseSpeciality" :key="index">
          <div class="pb-1 border-bottom pt-2 pb-2">
            <CRow class="profileView">
              <CCol lg="6" class="text-gray">Speciality</CCol>
              <CCol lg="6" class="text-black">
                {{ getSpeciality(speciality) }}
                <span v-if="speciality.main">{{ `(Main)` }}</span>
              </CCol>
            </CRow>
            <CRow v-if="isAHP" class="profileView">
              <CCol lg="6" class="text-gray">Sub Type</CCol>
              <CCol lg="6" class="text-black">
                {{ getSubType(speciality.sub_speciality) }}
              </CCol>
            </CRow>
            <CRow class="profileView">
              <CCol lg="6" class="text-gray">Experience</CCol>
              <CCol lg="6" class="text-black">{{
                speciality.experience_in_years || "--"
              }}</CCol>
            </CRow>
            <CRow class="profileView">
              <CCol lg="6" class="text-gray">Date Last Practice</CCol>
              <CCol lg="6" class="text-black">
                <span v-if="speciality.is_current">{{ `Current` }}</span>
                <span v-else>{{ speciality.last_practiced | formatDate }}</span>
              </CCol>
            </CRow>
            <CRow class="profileView">
              <CCol lg="6" class="text-gray">Action</CCol>
              <CCol lg="2" class="text-black">
                <CButton
                  type="button"
                  @click="editSplInterest(speciality)"
                  class="p-0 hand-icon"
                >
                  <i class="fas fa-pencil-alt"></i>
                </CButton>
              </CCol>
              <CCol col="2" class="text-black fw-600">
                <CButton
                  v-if="!speciality.main"
                  type="button"
                  @click="deleteSplInterest(speciality)"
                  class="p-0 hand-icon"
                >
                  <i class="fas fa-trash"></i>
                </CButton>
              </CCol>
            </CRow>
          </div>
        </div>
        <div v-if="!nurseSpeciality.length">
          <p class="text-center text-gray h6">
            No Specialization Information Found
          </p>
        </div>
      </div>
      <div v-else>
        <div
          v-if="nurseSpeciality.length"
          class="d-flex align-items-center documentScrolling document-table border"
        >
          <table class="table">
            <thead>
              <tr>
                <th class="nowrap">
                  Speciality {{ isNurse ? `/ Other Area of Expertise` : "" }}
                </th>
                <th class="nowrap" v-if="isAHP">Sub Type</th>
                <th class="nowrap">Years of Experience</th>
                <th class="nowrap">Date of Last Practice</th>
                <th v-if="isEditable" class="nowrap">Action</th>
              </tr>
            </thead>
            <tbody class="text-black">
              <tr v-for="(data, index) in nurseSpeciality" :key="index">
                <td>
                  {{ getSpeciality(data) }}
                  <span v-if="data.main">{{ `(Main)` }}</span>
                </td>
                <td v-if="isAHP">
                  {{ getSubType(data.sub_speciality) }}
                </td>
                <td>
                  {{ data.experience_in_years || "--" }}
                </td>
                <td>
                  <span v-if="data.is_current">{{ `Current` }}</span>
                  <span v-else>{{ data.last_practiced | formatDate }}</span>
                </td>
                <td v-if="isEditable">
                  <a
                    class="hand-icon btn pr-2 p-0"
                    @click="editSplInterest(data)"
                  >
                    <i class="fas fa-pencil-alt"></i> </a
                  ><span v-if="!data.main">&nbsp; | &nbsp;</span>
                  <a
                    v-if="!data.main"
                    class="hand-icon btn pl-2 p-0"
                    @click="deleteSplInterest(data)"
                  >
                    <i class="fas fa-trash"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <p class="text-center text-gray h6">
            No Special Interest Information Found
          </p>
        </div>
      </div>
    </div>
    <SpecializationForm
      :specializationInfo="specializationModal.data"
      :candidateInfo="profile"
      :isShowPopup="specializationModal.isShowPopup"
      :isEdit="specializationModal.isEdit"
      :nurseSpeciality="nurseSpeciality"
      @modalCallBack="specializationModalCallBack"
      v-if="specializationModal.isShowPopup"
      ref="specializationForm"
    >
    </SpecializationForm>
    <Modal
      v-if="deleteConfirmModal.isShowPopup"
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteSplInterestCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import { Role, getScope } from "@/helpers/helper";
import SpecializationForm from "../EditForm/SpecializationForm";
import Modal from "@/components/reusable/Modal";
import { mapActions, mapGetters } from "vuex";
import m from "moment";
import isJobSupplier from "@/mixins/isJobSupplier";

export default {
  mixins: [isJobSupplier],
  props: {
    specialInterests: {
      type: Array,
      default: () => [],
    },
    profile: {
      type: Object,
      default: () => null,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SpecializationForm,
    Modal,
  },
  data() {
    return {
      Role,
      currentUserRole: getScope(),
      specializationModal: {
        data: null,
        isShowPopup: false,
        isEdit: false,
      },
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        data: null,
      },
    };
  },
  computed: {
    ...mapGetters(["currentUserType"]),
    isEditable() {
      return (
        [Role.customerAdmin, Role.customerRecruiter].includes(
          this.currentUserType?.code || this.currentUserRole
        ) && !this.isJobSupplier || false
      );
    },
    isCustomerAdmin() {
      return this.currentUserRole === this.Role.customerAdmin;
    },
    nurseSpeciality() {
      return (
        (this.specialInterests &&
          this.specialInterests
            .filter(
              (val) =>
                val.speciality_id &&
                val.special_interest_id === null &&
                val.other_special_interest === null
            )
            .sort((a, b) => b.main - a.main)) ||
        []
      );
    },
    isAnyMainAvl() {
      return this.nurseSpeciality.some(({ main }) => main) || false;
    },
    isNurse() {
      return this.profile?.candidate_type_id === 2 || false;
    },
    isAHP() {
      return this.profile?.candidate_type_id === 3 || false;
    },
  },
  methods: {
    ...mapActions([
      "uploadSpecialInterest",
      "fetchspecialInterest",
      "updateSplInterest",
      "deleteCandidateSpecialInterest",
    ]),
    addSpecialization() {
      this.$parent.tooltipHide();
      this.specializationModal.isShowPopup = true;
      this.specializationModal.isEdit = false;
      this.specializationModal.data = null;
    },
    editSplInterest(data) {
      this.specializationModal.data = data;
      this.specializationModal.isEdit = true;
      this.specializationModal.isShowPopup = true;
    },
    specializationModalCallBack(action, payload) {
      if (action === false) this.specializationModal.isShowPopup = false;
      else {
        if (
          payload?.main &&
          ((this.specializationModal.isEdit && !payload.is_main) ||
            (!this.specializationModal.isEdit && this.isAnyMainAvl))
        ) {
          if (
            confirm(
              `Do you want to make this your Main Speciality ? - ${this.$refs.specializationForm?.profile?.speciality_id?.label}`
            )
          ) {
            this.saveSpecialInterest(payload);
          } else {
            this.specializationModal.isShowPopup = false;
            this.specializationModal.isEdit = false;
            this.specializationModal.data = null;
          }
        } else {
          this.saveSpecialInterest(payload);
        }
      }
    },
    saveSpecialInterest(payload) {
      let appendAction = null;
      if (
        this.specializationModal.data === null &&
        this.specializationModal.isEdit === false
      ) {
        appendAction = this.uploadSpecialInterest(payload);
      } else if (this.specializationModal.isEdit) {
        appendAction = this.updateSplInterest(payload);
      }
      Promise.all([appendAction]).then((res) => {
        if (res) {
          this.specializationModal.isShowPopup = false;
          this.specializationModal.isEdit = false;
          this.specializationModal.data = null;
          this.fetchspecialInterest();
        }
      });
    },
    deleteSplInterest(data) {
      let speciality = null;
      if (data?.speciality?.speciality === "Other - not listed") {
        speciality = data?.other_speciality || "";
      } else speciality = data?.speciality?.speciality;
      this.deleteConfirmModal.modalContent =
        "Do you want to delete this Specialization - " +
        speciality +
        `${data?.main ? "(Main) ?" : "?"}`;
      this.deleteConfirmModal.isShowPopup = true;
      this.deleteConfirmModal.data = data;
    },
    deleteSplInterestCallBack(action) {
      if (action === "Confirm") {
        this.deleteCandidateSpecialInterest(
          this.deleteConfirmModal?.data?.candidate_speciality_id
        );
      }
      this.deleteConfirmModal.isShowPopup = false;
      this.deleteConfirmModal.data = null;
    },
    getSubType(data) {
      return data?.sub_speciality || "--";
    },
    getSpeciality(data) {
      if(this.isNurse && data?.speciality_id && data?.sub_speciality_id)
        return data?.speciality && data?.sub_speciality
          ? `${data?.speciality?.speciality} - ${data?.sub_speciality?.sub_speciality}`
          : "--";
      return data?.speciality &&
        data.speciality?.speciality !== "Other - not listed"
        ? data?.speciality?.speciality
        : `${data?.other_speciality}`;
    },
  },
  filters: {
    formatDate(data) {
      if (data != null) return m(data).format("MMM YYYY");
      return "--";
    },
  },
};
</script>
