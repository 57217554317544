<template>
  <div>
    <a
      class="d-flex btn pl-0"
      @click="
        ($parent.isExpand.pqr = !$parent.isExpand.pqr), $parent.checkExpandAll()
      "
    >
      <span class="pr-1">
        <i
          class="text-primary fas"
          :class="$parent.isExpand.pqr ? 'fa-angle-down' : 'fa-angle-right'"
          alt="angle-down"
        />
      </span>
      <h5 class="text-primary text-left">
        Eligibility
        <span class="nowrap"
          >[{{ pqrLevels.length }} Record{{ pqrLevels.length > 1 ? "s" : "" }}]</span
        >
      </h5>
    </a>
    <div v-if="$parent.isExpand.pqr">
      <div>
        <p
          class="align-center text-center h5"
          :class="pqrLevels.length ? 'text-primary' : 'text-gray'"
        >
          Eligibility Found: {{ pqrLevels.length }}
        </p>
      </div>

      <div>
        <div class="d-flex align-items-center justify-content-between pb-2">
          <h5 class="text-primary"></h5>
          <div
            class="d-flex justify-content-start sticky-header"
            v-if="pqrLevels.length"
          >
            <CButton @click="pqr_step -= 1" :disabled="pqr_step == 0">
              <CIcon name="cil-caret-left" />
            </CButton>
            <CButton
              @click="pqr_step += 1"
              :disabled="pqr_step + 1 == pqrLevels.length"
            >
              <CIcon name="cil-caret-right" />
            </CButton>
          </div>
        </div>
        <div v-if="pqrLevels.length">
          <div class="m-0">
            <CRow
              v-for="(data, parentIndex) in pqrLevels"
              :key="parentIndex"
            >
              <CCol lg="12" v-if="parentIndex === pqr_step">
                <div v-if="isMobile">
                  <PQRInfoCard :pqrLevel="data" :profile="profile" />
                </div>
                <CRow v-else>
                  <CCol
                    lg="12"
                  >
                    <PQRInfoCard :pqrLevel="data" :profile="profile" />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div>
        <div v-else>
          <p class="text-center text-gray h6">No PQR Matches Found</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import PQRInfoCard from "@/containers/CandidateDetailsTalentFind/ProfileSection/PQRInfo/PQRInfoCard";
export default {
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    isMobile: {
      type: Boolean,
      default: () => false,
    },
    pqrLevels: {
      type: Array,
      default: () => [],
    },
    candidatePQRLevel: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    PQRInfoCard,
  },
  data() {
    return {
      pqr_step: 0,
    };
  },
  computed: {
    isMatching() {
      if (this.candidatePQRLevel != null)
        return this.candidatePQRLevel.is_matching;
      return null;
    },
    pqrMessage() {
      if (this.candidatePQRLevel != null) return this.candidatePQRLevel.message;
      return null;
    },
    mainQualification() {
      if (this.profile.main_qualification != null)
        return (
          this.profile.main_qualification.qualification_with_acronym || "--"
        );
      return "--";
    },
    mainQualificationCountry() {
      if (this.profile.main_qualification != null)
        return this.profile.main_qualification.country_name || "--";
      return "--";
    },
    mainQualificationType() {
      if (this.profile.main_qualification != null)
        return this.profile.qualification_type_name || "--";
      return "--";
    },
    pqrLevelCustom() {
      if (!this.isMobile)
        return this.pqrLevels.reduce((a, c, i) => {
          return i % 2 === 0 ? a.concat([this.pqrLevels.slice(i, i + 2)]) : a;
        }, []);
      return this.pqrLevels;
    },
    pqrLevel2D() {
      return this.pqrLevels.reduce((a, c, i) => {
        return i % 2 === 0 ? a.concat([this.pqrLevels.slice(i, i + 2)]) : a;
      }, []);
    },
  },
  methods: {
    calculatePQR() {
      this.$emit("calculatePQRLevel");
    },
  },
  filters: {
    moment: function (date) {
      if (date) return moment(date).format("DD-MMM-YYYY");
      return "--";
    },
  },
};
</script>
